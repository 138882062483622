import { Suspense } from "react";
import { AuthProvider } from "common/providers/AuthProvider";
import { LoadingProvider } from "common/providers/LoadingProvider";
import { AlertsProvider } from "common/providers/alerts";
// eslint-disable-next-line import/no-extraneous-dependencies
import { LocalizationProvider } from "@mui/x-date-pickers";
// eslint-disable-next-line import/no-extraneous-dependencies
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { NotificationProvider } from 'common/providers/NotificationProvider';

type Props = {
  children: JSX.Element;
};

function AppProvider({ children }: Props) {
  return (
    <Suspense>
      <LoadingProvider>
        <AlertsProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <AuthProvider>{children}</AuthProvider>
          </LocalizationProvider>
        </AlertsProvider>
      </LoadingProvider>
    </Suspense>
  );
}

export default AppProvider;
