// eslint-disable-next-line import/no-extraneous-dependencies
import { TimePicker as TP } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";

type Props = {
  onChange?: (value: any) => void;
  value?: any;
  label?: string;
};

function TimePicker({ value = null, onChange = () => {}, label = "" }: Props) {
  const formattedValue = !value
    ? dayjs().date(1).month(0).year(2024).second(0).millisecond(0)
    : dayjs(value).date(1).month(0).year(2024).second(0).millisecond(0);
  const handleChange = (newDate: any) => onChange(newDate);

  return (
    <TP
      label={label}
      value={formattedValue}
      onChange={handleChange}
      timezone="Europe/Paris"
      format="H:mm"
      ampm={false}
    />
  );
}

export default TimePicker;
