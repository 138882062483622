import useRouter from "common/hooks/use-router";
import useItem from "common/hooks/useItem";
import { useCallback, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import AlertsContext from "common/providers/alerts";
import UserForm from "./UserForm";

function User() {
  const { t } = useTranslation();
  const router = useRouter();
  const { setAlert } = useContext(AlertsContext);

  const id = router.query.id as string;

  const {
    saveItem,
    updateItem,
    item: otherUser,
    error,
    isFetching,
  }: any = useItem("users", id, {
    populate: ["role", "avatar"],
  });

  useEffect(() => {
    if (error) {
      setAlert(
        error === "Username already taken"
          ? t("common.emailAlreadyTaken")
          : t("common.error"),
        "danger"
      );
    }
  }, [error, setAlert, t]);

  const back = useCallback(() => {
    if (!error) {
      setAlert(t("common.saved"), "success");
      router.navigate("/users");
    }
  }, [setAlert, router, error, t]);

  const onSubmit = useCallback(
    async (dataUser: any) => {
      const user = { ...dataUser };

      try {
        if (user.id) {
          await updateItem(user.id, user, false);
        } else {
          await saveItem(user, false);
        }
      } catch (e: any) {
        return e;
      }

      back();
      return true;
    },
    [updateItem, back, saveItem]
  );

  return isFetching || (id && !otherUser) ? null : (
    <UserForm onSubmit={onSubmit} editedUser={otherUser} />
  );
}

export default User;
