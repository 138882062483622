import Icon, { IconNames } from "components/Icon/Icon";
import FormControl from "../FormControl/FormControl";

interface IIconFormControl {
  icon: IconNames;
  iconClassName?: string;
  formControlName: string;
  formControlTitle: string;
  required?: boolean;
  type?: string;
}

function IconFormControl({
  icon,
  iconClassName = "w-10 h-10",
  formControlName,
  formControlTitle,
  required = false,
  type = "number",
}: IIconFormControl) {
  return (
    <div className="flex flex-row gap-4">
      <div className="flex flex-col items-center justify-center border-r border-slate-200 pe-2">
        <Icon className={iconClassName} name={icon} />
      </div>
      <FormControl
        name={formControlName}
        title={formControlTitle}
        required={required}
        type={type}
        className="w-full"
      />
    </div>
  );
}

export default IconFormControl;
