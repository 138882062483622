// import Loader from 'common/components/Loader/Loader';
import AlertsContext from "common/providers/alerts";
import useRouter from "common/hooks/use-router";
import useItem from "common/hooks/useItem";
import { useCallback, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ParkingType as ParkingTypeType } from "types/ParkingType";
import NotificationForm from "./NotificationForm";

function Notification() {
  const { t } = useTranslation();
  const router = useRouter();
  const { setAlert } = useContext(AlertsContext);

  const id = router.query.id as string;

  const { saveItem, updateItem, item, error, isFetching }: any =
    useItem<ParkingTypeType>("notifications", id, { populate: ["parking"] });

  useEffect(() => {
    if (error) {
      setAlert(error, "danger");
    }
  }, [error, setAlert]);

  const back = useCallback(() => {
    if (!error) {
      setAlert(t("common.saved"), "success");
      router.navigate("/notifications");
    }
  }, [setAlert, router, error, t]);

  const onSubmit = useCallback(
    async (dataNotification: any) => {
      const newNotification = { ...dataNotification };

      try {
        if (newNotification.id) {
          await updateItem(newNotification.id, newNotification);
        } else {
          await saveItem(newNotification);
        }
      } catch (e: any) {
        return e;
      }
      back();
      return true;
    },
    [updateItem, back, saveItem]
  );

  return isFetching || (id && !item) ? null : (
    <NotificationForm onSubmit={onSubmit} notification={item} />
  );
}

export default Notification;
