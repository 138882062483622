import { useTranslation } from "react-i18next";
import React, { useEffect, useMemo, useState } from "react";
// import dayjs from "dayjs";
import fetchJSON from "common/utils/fetchJSON";
import ButtonGroup from "common/components/ButtonGroup/ButtonGroup";
import {
  format,
  subMonths,
  //  addDays,
  startOfWeek,
  //  addMonths,
} from "date-fns";
import { fr as french } from "date-fns/locale";
// import { DayPicker } from "react-day-picker";
// import fr from "date-fns/locale/fr";
import DateRangePicker from "common/components/DatePicker/DateRangePicker";
import ParkingSelect from "components/select/Parking/ParkingSelect";
import Icon from "components/Icon/Icon";
import Header from "common/components/Header/Header";
import { Parking } from "types/Parking";
import { BsGraphUp } from "react-icons/bs";

function Stats() {
  const { t } = useTranslation();

  const maxDate = new Date();
  const defaultStartDate = subMonths(maxDate, 1);
  const [startDate, setStartDate] = useState<Date | undefined>(
    defaultStartDate
  );
  const [endDate, setEndDate] = useState<Date | undefined>(maxDate);
  const [company, setCompany] = useState<number | string | undefined>(
    undefined
  );
  const [step, setStep] = useState("day");
  const [data, setData] = useState<any>({});
  const [, setIsFetching] = useState<boolean>(false);

  const buildUrl = useMemo(() => {
    if (startDate && endDate) {
      if (company) {
        return `parkings/stats/${company}/${format(
          startDate as Date,
          "yyyy-MM-dd"
        )}/${format(endDate as Date, "yyyy-MM-dd")}/${step}`;
      }
      return `parkings/stats/${format(
        startDate as Date,
        "yyyy-MM-dd"
      )}/${format(endDate as Date, "yyyy-MM-dd")}/${step}`;
    }
    return null;
  }, [company, startDate, endDate, step]);

  useEffect(() => {
    if (buildUrl) {
      const getData = async () => {
        setIsFetching(true);
        const res = await fetchJSON({
          url: buildUrl,
          method: "GET",
        });
        setIsFetching(false);
        setData(res);
      };

      getData();
    }
  }, [company, startDate, endDate, step, buildUrl]);

  const generalData = useMemo(() => {
    return {
      topParkings: data?.general?.topParkings,
    };
  }, [data]);

  const stats = useMemo(
    () => [
      {
        name: t("stats.numberOfAppLaunched"),
        icon: (
          <Icon
            name="BsPhoneVibrate"
            className="h-6 w-6 text-white"
            aria-hidden="true"
          />
        ),
        stat:
          data?.filtered?.reduce(
            (sum: number, d: any) => sum + d.numberOfAppLaunched,
            0
          ) ?? 0,
        active: true,
      },
      {
        name: t("stats.numberOfDetailsDisplayed"),
        icon: (
          <Icon
            name="BuildingOfficeIcon"
            className="h-6 w-6 text-white"
            aria-hidden="true"
          />
        ),
        stat:
          data?.filtered?.reduce(
            (sum: number, d: any) => sum + d.numberOfDetailsDisplayed,
            0
          ) ?? 0,
        active: true,
      },
      {
        name: t("stats.numberOfItineraries"),
        icon: (
          <Icon
            name="GiPathDistance"
            className="h-6 w-6 text-white"
            aria-hidden="true"
          />
        ),
        stat:
          data?.filtered?.reduce(
            (sum: number, d: any) => sum + d.numberOfItineraries,
            0
          ) ?? 0,
        active: true,
      },
      {
        name: t("stats.numberOfSeePrices"),
        icon: (
          <Icon
            name="AiOutlineEuro"
            className="h-6 w-6 text-white"
            aria-hidden="true"
          />
        ),
        stat:
          data?.filtered?.reduce(
            (sum: number, d: any) => sum + d.numberOfSeePrices,
            0
          ) ?? 0,
        active: true,
      },
      {
        name: t("stats.numberOfFavs"),
        icon: (
          <Icon
            name="AiFillStar"
            className="h-6 w-6 text-white"
            aria-hidden="true"
          />
        ),
        stat:
          data?.filtered?.reduce(
            (sum: number, d: any) => sum + d.numberOfFavs,
            0
          ) ?? 0,
        active: true,
      },
      {
        name: t("stats.numberOfPushNotificationsEnabled"),
        icon: (
          <Icon
            name="MdOutlineNotificationsActive"
            className="h-6 w-6 text-white"
            aria-hidden="true"
          />
        ),
        stat:
          data?.filtered?.reduce(
            (sum: number, d: any) => sum + d.numberOfPushNotificationsEnabled,
            0
          ) ?? 0,
        active: company === undefined,
      },
      {
        name: t("stats.numberOfMySpace"),
        icon: (
          <Icon
            name="RiParkingBoxLine"
            className="h-6 w-6 text-white"
            aria-hidden="true"
          />
        ),
        stat:
          data?.filtered?.reduce(
            (sum: number, d: any) => sum + d.numberOfMySpace,
            0
          ) ?? 0,
        active: company === undefined,
      },
      {
        name: t("stats.numberOfMySpaceSaved"),
        icon: (
          <Icon
            name="RiParkingBoxFill"
            className="h-6 w-6 text-white"
            aria-hidden="true"
          />
        ),
        stat:
          data?.filtered?.reduce(
            (sum: number, d: any) => sum + d.numberOfMySpaceSaved,
            0
          ) ?? 0,
        active: company === undefined,
      },
      {
        name: t("stats.numberOfCalculator"),
        icon: (
          <Icon
            name="AiOutlineEuro"
            className="h-6 w-6 text-white"
            aria-hidden="true"
          />
        ),
        stat:
          data?.filtered?.reduce(
            (sum: number, d: any) => sum + d.numberOfCalculator,
            0
          ) ?? 0,
        active: true,
      },
    ],
    [data?.filtered, t, company]
  );

  const getGradientColorForRanking = (position: number) => {
    switch (position) {
      case 1:
        return "bg-gradient-to-br from-amber-400 to-yellow-300";
      case 2:
        return "bg-gradient-to-br from-gray-200 via-gray-400 to-gray-600";
      case 3:
        return "bg-gradient-to-br from-orange-200 to-amber-700";
      default:
        return "bg-slate-700";
    }
  };

  return (
    <div className="mx-auto max-w-full py-4 px-4 text-slate-500">
      <Header
        title={t("titlePages.stats")}
        subTitle={t("titleLegends.stats")}
        Icon={BsGraphUp}
        isBeforeFilters
      />
      <div className="flex-1">
        <div className="flex flex-row justify-around justify-items-center my-5 mt-8">
          <div className="flex flex-row items-center gap-2">
            <div>{t("stats.dates")}</div>
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              onStartChange={setStartDate}
              onEndChange={setEndDate}
            />
          </div>
          <div className="flex flex-row items-center gap-2">
            {t("stats.scale")}
            <ButtonGroup
              value={step}
              onChange={setStep}
              multiple={false}
              clearable={false}
              disabled={false}
              required
              options={[
                { value: "month", label: t("stats.month") },
                { value: "week", label: t("stats.week") },
                { value: "day", label: t("stats.day") },
              ]}
              className="stepChoice"
            />
          </div>
          <div className="flex flex-row items-center gap-2">
            {t("stats.parking")}
            <ParkingSelect
              value={company}
              onChange={(c: any) => {
                setData({});
                setCompany(c?.id);
              }}
              placeholder={t("stats.choseParking")}
              clearable
            />
          </div>
        </div>

        {startDate && endDate && (
          <div className="flex flex-row justify-around justify-items-center my-3 font-bold">
            {step === "day" &&
              `${t("stats.fromToDay", {
                from: format(startDate, "dd/MM/yyyy"),
                to: format(endDate, "dd/MM/yyyy"),
              })} :`}
            {step === "week" &&
              `${t("stats.fromToWeek", {
                from: format(
                  startOfWeek(startDate, { locale: french }),
                  "EEEE dd/MM/yyyy",
                  { locale: french }
                ),
                to: format(
                  startOfWeek(endDate, { locale: french }),
                  "EEEE dd/MM/yyyy",
                  { locale: french }
                ),
              })} :`}
            {step === "month" &&
              `${t("stats.fromToMonth", {
                from: format(startDate, "MMMM yyyy", { locale: french }),
                to: format(endDate, "MMMM yyyy", { locale: french }),
              })} :`}
          </div>
        )}
        <div className="mt-3 grid md:grid-rows-3 md:grid-flow-col gap-3 mb-10">
          {stats
            ?.filter((s) => s.active)
            ?.map((item) => (
              <div
                key={item.name}
                className="flex flex-row px-4 py-4 mt-2 w-full relative items-center bg-white cursor-pointer rounded-lg border border-slate-150"
              >
                <div className="bg-slate-700 rounded-md p-4 mr-4">
                  {item.icon}
                </div>
                <div>
                  <p className="text-sm font-medium text-gray-500 truncate">
                    {item.name}
                  </p>
                  <p className="text-2xl font-semibold text-gray-900">
                    {item.stat}
                  </p>
                </div>
              </div>
            ))}
        </div>

        <h3 className="flex flex-row justify-around justify-items-center text-lg leading-6 text-gray-900 font-bold mt-8 mb-8">
          {t("stats.generalInfoTitle")}
        </h3>
        <div className="flex flex-col md:flex-row justify-around justify-items-center mb-8">
          {generalData &&
            generalData?.topParkings?.map(
              (topParking: Parking, index: number) => {
                return (
                  <div
                    key={topParking?.id}
                    className="md:basis-1/5 flex flex-row md:flex-col lg:flex-row mx-2 px-4 py-4 mt-2 w-full relative items-center bg-white cursor-pointer rounded-lg border border-slate-150 transition-all font-bold justify-items-center"
                  >
                    <div
                      className={`flex w-12 h-12 mr-4 ${getGradientColorForRanking(
                        index + 1
                      )} rounded-md justify-center items-center text-xl text-white`}
                    >
                      {(index + 1).toString()}
                    </div>
                    {topParking.name}
                  </div>
                );
              }
            )}
        </div>
      </div>
    </div>
  );
}

export default Stats;
