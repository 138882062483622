import Icon, { IconNames } from "components/Icon/Icon";
import { useTranslation } from "react-i18next";
import { getSpecialStyle } from "../CheckBoxBlockItem/CheckBoxBlockItem";
import EmptyResult from "../EmptyResult/EmptyResult";
import TooltipWrapper from "../TooltipWrapper/TooltipWrapper";

export type Info = {
  nameKey?: string;
  name?: string;
  infoKey?: string;
  info?: string;
  icon: IconNames;
  visible?: boolean;
};

interface IInfoGroup {
  infoGroups: Info[];
}

function InfoGroup({ infoGroups }: IInfoGroup) {
  const { t } = useTranslation();
  if (!infoGroups?.length) {
    return <EmptyResult text={t("forms.noMoreInfo")} />;
  }

  const list = infoGroups?.map(
    ({
      nameKey = "",
      infoKey = "",
      info = "",
      icon,
      name = "",
      visible = true,
    }) => {
      const node = () => (
        <span
          key={nameKey}
          className={`flex gap-1 h-6 items-center border ${getSpecialStyle(
            false,
            true
          )} rounded-lg py-1 px-2 text-xs`}
          /* short ? "max-w-[150px]" : "" */
        >
          <Icon name={icon ?? "UsersIcon"} className="w-4 h-4" />
          <span>{name.length > 0 ? name : t(nameKey)}</span>
        </span>
      );
      return (
        visible &&
        (info || infoKey ? (
          <TooltipWrapper
            className="mr-1 my-1"
            content={<span>{info.length > 0 ? info : t(infoKey)}</span>}
          >
            {node()}
          </TooltipWrapper>
        ) : (
          <div className="mr-1 my-1">{node()}</div>
        ))
      );
    }
  );
  return <div className="flex gap-1 items-center flex-wrap">{list}</div>;
}

export default InfoGroup;
