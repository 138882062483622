import RemoteSelect from "common/components/RemoteSelect/RemoteSelect";
import { ParkingType } from "types/ParkingType";

const processOptions = (options: ParkingType[]) =>
  options.map(({ id, name }) => ({
    value: id,
    label: name,
  }));

function ParkingTypeSelect(props: any) {
  return (
    <RemoteSelect
      url="parking-types"
      processOptions={processOptions}
      sort="name:ASC"
      {...props}
    />
  );
}

export default ParkingTypeSelect;
