import EditHeader from "common/components/EditHeader/EditHeader";
import HeaderButtonsEdit from "common/components/EditHeader/HeaderButtonsEdit";
import FormControl from "common/components/FormControl/FormControl";
import FormScrollError from "common/components/FormScrollError/FormScrollError";
import useRouter from "common/hooks/use-router";
import { useMemo, useState } from "react";
import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import arrayMutators from "final-form-arrays";
import SectionTitle from "common/components/SectionTitle/SectionTitle";
import "photoswipe/dist/photoswipe.css";
import MapField from "common/components/MapField/MapField";
import ParkingTypeSelect from "components/select/ParkingType/ParkingTypeSelect";
import useList from "common/hooks/useList";
import { Service } from "types/Service";
import ToggleSwitch from "common/components/ToggleSwitch/ToggleSwitch";
import IconFormControl from "common/components/IconFormControl/IconFormControl";
import { BiMoveVertical } from "react-icons/bi";
import Button from "common/components/Button/Button";
import { FieldArray } from "react-final-form-arrays";
import SortableList from "common/components/SortableList/SortableList";
import FormAddButton from "common/components/FormAddButton.tsx/FormAddButton";
import dayjs from "dayjs";
import TimeSelect from "components/select/TimeSelect/TimeSelect";

function DragHandle(props: any) {
  return (
    <BiMoveVertical
      {...props}
      className="w-4 h-4 cursor-move hover:text-primary border-none"
    />
  );
}

const renderDaySchedule = (i: number) => {
  const hour = Math.floor(i / 4);
  const minute = (i % 4) * 15;

  let label = "";
  if (hour !== 0) {
    label += `${hour}h`;
  }
  if ((minute !== 0 && hour === 0) || hour === 0) {
    label += `${minute}min`;
  } else if (minute !== 0) {
    label += `${minute}`;
  }

  return label;
};

function renderTimeDays(days: number[], daysList: any) {
  let daysFormatted = "";

  if (days !== undefined && days?.length > 0) {
    days?.map((day, index) => {
      daysFormatted += daysList.find((daysL: any) => daysL.value === day).label;
      if (index < days.length - 2) {
        daysFormatted += ", ";
      } else if (index < days.length - 1) {
        daysFormatted += " et ";
      }

      return day;
    });
  }

  return days?.length > 0 ? daysFormatted : "...";
}

function renderEuroPrice(price: number) {
  return Intl.NumberFormat("fr-FR", {
    style: "currency",
    currency: "EUR",
  }).format(price);
}

function PricesConfigurationSumUp(values: any, key: string) {
  const { t } = useTranslation();

  const daysList = [
    { value: 1, label: t("common.mondays") },
    { value: 2, label: t("common.tuesdays") },
    { value: 3, label: t("common.wednesdays") },
    { value: 4, label: t("common.thursdays") },
    { value: 5, label: t("common.fridays") },
    { value: 6, label: t("common.saturdays") },
    { value: 0, label: t("common.sundays") },
  ];

  // TODO English version has to be totally different
  return values?.[key] !== undefined ? (
    <div>
      {values[key]?.freeItem &&
        values[key]?.freeItem[0] &&
        `- Gratuit pour un stationnement inférieur à ${renderDaySchedule(
          values[key]?.freeItem[0].duration
        )}. Après cela, le prix de départ est de ${renderEuroPrice(
          values[key]?.freeItem[0].startingPrice
        )}`}
      <div className="flex flex-col">
        {values[key]?.prices &&
          values[key].prices?.map((price: any, index: number) => {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <div key={`priceItem-${index}`}>
                {values[key].prices[index + 1]
                  ? `- De ${renderDaySchedule(
                      price.minDuration
                    )} de stationnement à
                  ${renderDaySchedule(
                    values[key].prices[index + 1].minDuration
                  )}
                  de stationnement : ${renderEuroPrice(price.price)} / 15min.`
                  : `- À partir de ${renderDaySchedule(
                      price.minDuration
                    )} de stationnement :
                  ${renderEuroPrice(price.price)} / 15min.`}
              </div>
            );
          })}
      </div>
      <div className="flex flex-col">
        {values[key]?.packages &&
          values[key].packages?.map((pck: any, index: number) => {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <div key={`package-${index}`}>
                {`- Pour une durée de stationnement supérieur à ${renderDaySchedule(
                  pck.minDuration
                )} et inférieur à ${renderDaySchedule(
                  pck.maxDuration
                )} : ${renderEuroPrice(pck.price)}.`}
              </div>
            );
          })}
      </div>
      {values[key]?.night &&
        values[key]?.night[0] &&
        `- De nuit, de ${dayjs(values[key]?.night[0].startingTime).format(
          "H:mm"
        )} à ${dayjs(values[key]?.night[0].endingTime).format(
          "H:mm"
        )}, pour au moins ${renderDaySchedule(
          values[key]?.night[0].minDuration
        )} de stationnement : ${renderEuroPrice(values[key]?.night[0].price)}.`}
      <div>
        {values[key]?.fullDay &&
          values[key]?.fullDay[0] &&
          `- Pour une journée supplémentaire : ${renderEuroPrice(
            values[key]?.fullDay[0].price
          )}.`}
      </div>
      <div className="flex flex-col">
        {values[key]?.bonuses &&
          values[key].bonuses?.map((bonus: any, index: number) => {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <div key={`package-${index}`}>
                {`- Tous les ${renderTimeDays(bonus.days, daysList)} de ${dayjs(
                  bonus.startingTime
                ).format("H:mm")} à ${dayjs(bonus.endingTime).format(
                  "H:mm"
                )} : ${
                  bonus.price ? `${renderEuroPrice(bonus.price)}` : "Gratuit"
                }.`}
              </div>
            );
          })}
      </div>
    </div>
  ) : (
    <div>Pas de configuration</div>
  );
}

function PricesConfigurationItem(
  form: any,
  values: any,
  rest: any,
  key: string
) {
  const { t } = useTranslation();

  return (
    <div className="relative mt-2 flex gap-3 text-sm items-start justify-between w-full rounded-lg border border-slate-200 py-2 pl-3 pr-1 bg-slate-100">
      <div className="flex flex-col w-full gap-3">
        <div className="flex flex-row gap-2 justify-between items-center">
          {values?.[key]?.prices[rest.index + 1] ? (
            <p className="field-title">De</p>
          ) : (
            <p className="field-title">À partir de</p>
          )}
          <FormControl
            containerStyle="w-full"
            name={`${rest.name}.minDuration`}
            type="custom"
            required
          >
            <TimeSelect />
          </FormControl>
          {values?.[key]?.prices[rest.index + 1]?.minDuration && (
            <p className="field-title">{`à ${
              renderDaySchedule(
                values?.[key]?.prices[rest.index + 1]?.minDuration
              ) ?? ""
            } `}</p>
          )}
        </div>
        <div className="text-xs gap-2">
          <FormControl
            name={`${rest.name}.price`}
            titleKey={t("forms.priceRange")}
            containerStyle="w-full"
            required
            type="number"
          />
        </div>
      </div>

      <div className="">
        <Button
          type="error-line"
          onClick={() => rest.fields.remove(rest.index)}
          icon="TrashIcon"
          className="min-h-[3rem] w-12 p-0 flex justify-center"
          compact
        />
      </div>
    </div>
  );
}

function PricesConfigurationPackage(form: any, values: any, rest: any) {
  const { t } = useTranslation();

  return (
    <div className="relative mt-2 flex gap-3 text-sm items-start justify-between w-full rounded-lg border border-slate-200 py-2 pl-3 pr-1 bg-slate-100">
      <div className="flex flex-col w-full gap-3">
        <div className="flex flex-row gap-2 justify-between items-center">
          <FormControl
            titleKey={t("forms.from")}
            containerStyle="w-full"
            name={`${rest.name}.minDuration`}
            type="custom"
            required
          >
            <TimeSelect />
          </FormControl>
          <FormControl
            containerStyle="w-full"
            name={`${rest.name}.maxDuration`}
            type="custom"
            titleKey={t("forms.to")}
            required
          >
            <TimeSelect />
          </FormControl>
        </div>
        <div className="text-xs gap-2">
          <FormControl
            name={`${rest.name}.price`}
            titleKey={t("forms.price")}
            containerStyle="w-full"
            required
            type="number"
          />
        </div>
      </div>

      <div className="">
        <Button
          type="error-line"
          onClick={() => rest.fields.remove(rest.index)}
          icon="TrashIcon"
          className="min-h-[3rem] w-12 p-0 flex justify-center"
          compact
        />
      </div>
    </div>
  );
}

function PricesConfigurationPackageBonus(form: any, values: any, rest: any) {
  const { t } = useTranslation();

  const daysList = [
    { value: 1, label: t("common.monday") },
    { value: 2, label: t("common.tuesday") },
    { value: 3, label: t("common.wednesday") },
    { value: 4, label: t("common.thursday") },
    { value: 5, label: t("common.friday") },
    { value: 6, label: t("common.saturday") },
    { value: 0, label: t("common.sunday") },
  ];

  return (
    <div className="relative mt-2 flex gap-3 text-sm items-start justify-between w-full rounded-lg border border-slate-200 py-2 pl-3 pr-1 bg-slate-100">
      <div className="flex flex-col w-full gap-3">
        <div className="text-xs gap-2">
          <FormControl
            type="select"
            title={t("forms.days")}
            name={`${rest.name}.days`}
            options={daysList}
            multiple
            required
          />
        </div>
        <div className="flex flex-row gap-2 justify-between items-center">
          <FormControl
            name={`${rest.name}.startingTime`}
            required
            titleKey={t("forms.from")}
            type="time"
          />
          <FormControl
            name={`${rest.name}.endingTime`}
            required
            type="time"
            titleKey={t("forms.to")}
          />
        </div>
        <div className="text-xs gap-2">
          <FormControl
            name={`${rest.name}.price`}
            titleKey={t("forms.price")}
            containerStyle="w-full"
            required
            type="number"
          />
        </div>
      </div>

      <div className="">
        <Button
          type="error-line"
          onClick={() => rest.fields.remove(rest.index)}
          icon="TrashIcon"
          className="min-h-[3rem] w-12 p-0 flex justify-center"
          compact
        />
      </div>
    </div>
  );
}

function PricesConfigurationNight(form: any, values: any, rest: any) {
  const { t } = useTranslation();

  return (
    <div className="relative mt-2 flex gap-3 text-sm items-start justify-between w-full rounded-lg border border-slate-200 py-2 pl-3 pr-1 bg-slate-100">
      <div className="flex flex-col w-full gap-3">
        <div className="flex flex-row gap-2 justify-between items-center">
          <FormControl
            name={`${rest.name}.startingTime`}
            required
            titleKey={t("forms.from")}
            type="time"
          />
          <FormControl
            name={`${rest.name}.endingTime`}
            required
            type="time"
            titleKey={t("forms.to")}
          />
        </div>
        <div className="flex flex-col gap-2">
          <FormControl
            containerStyle="w-full"
            name={`${rest.name}.minDuration`}
            type="custom"
            titleKey={t("forms.minDuration")}
            required
          >
            <TimeSelect />
          </FormControl>
        </div>
        <div className="text-xs gap-2">
          <FormControl
            name={`${rest.name}.price`}
            titleKey={t("forms.price")}
            containerStyle="w-full"
            required
            type="number"
          />
        </div>
      </div>

      <div className="">
        <Button
          type="error-line"
          onClick={() => rest.fields.remove(rest.index)}
          icon="TrashIcon"
          className="min-h-[3rem] w-12 p-0 flex justify-center"
          compact
        />
      </div>
    </div>
  );
}

function PricesConfigurationFree(form: any, values: any, rest: any) {
  const { t } = useTranslation();

  return (
    <div className="relative mt-2 flex gap-3 text-sm items-start justify-between w-full rounded-lg border border-slate-200 py-2 pl-3 pr-1 bg-slate-100">
      <div className="flex flex-col w-full gap-3">
        <div className="gap-2">
          <FormControl
            containerStyle="w-full"
            name={`${rest.name}.duration`}
            type="custom"
            titleKey={t("forms.duration")}
            required
          >
            <TimeSelect />
          </FormControl>
        </div>
        <div className="gap-4">
          <FormControl
            name={`${rest.name}.startingPrice`}
            titleKey={t("forms.startingFreePrice")}
            containerStyle="w-full"
            required
            type="number"
          />
        </div>
      </div>

      <div className="">
        <Button
          type="error-line"
          onClick={() => rest.fields.remove(rest.index)}
          icon="TrashIcon"
          className="min-h-[3rem] w-12 p-0 flex justify-center"
          compact
        />
      </div>
    </div>
  );
}

function PricesConfigurationFullDay(form: any, values: any, rest: any) {
  const { t } = useTranslation();

  return (
    <div className="relative mt-2 flex gap-3 text-sm items-start justify-between w-full rounded-lg border border-slate-200 py-2 pl-3 pr-1 bg-slate-100">
      <div className="flex flex-col w-full gap-3">
        <div className="gap-4">
          <FormControl
            name={`${rest.name}.price`}
            titleKey={t("forms.price")}
            containerStyle="w-full"
            required
            type="number"
          />
        </div>
      </div>

      <div className="">
        <Button
          type="error-line"
          onClick={() => rest.fields.remove(rest.index)}
          icon="TrashIcon"
          className="min-h-[3rem] w-12 p-0 flex justify-center"
          compact
        />
      </div>
    </div>
  );
}

function SubscriptionItem(form: any, values: any, rest: any) {
  const { t } = useTranslation();

  return (
    <div className="relative mt-2 flex gap-3 text-sm items-start justify-between w-full rounded-lg border border-slate-200 py-2 pl-3 pr-1 bg-slate-100">
      <div
        className="min-h-[3rem] w-12 p-0 flex justify-center items-center cursor-pointer hover:text-primary bg-white rounded-sm border-slate-200"
        {...rest.dragProps}
      >
        <DragHandle />
      </div>

      <div className="flex flex-col w-full gap-3">
        <div className="text-xs gap-2">
          <FormControl
            name={`${rest.name}.title`}
            titleKey={t("forms.subscriptionTitle")}
            containerStyle="w-full"
            required
          />
        </div>
        <div className="text-xs gap-2">
          <FormControl
            name={`${rest.name}.price`}
            titleKey={t("forms.price")}
            containerStyle="w-full"
            required
          />
        </div>
        <div className="text-xs gap-2">
          <FormControl
            name={`${rest.name}.description`}
            titleKey={t("forms.description")}
            containerStyle="w-full"
          />
        </div>
      </div>

      <div className="">
        <Button
          type="error-line"
          onClick={() => rest.fields.remove(rest.index)}
          icon="TrashIcon"
          className="min-h-[3rem] w-12 p-0 flex justify-center"
          compact
        />
      </div>
    </div>
  );
}

function PriceDescriptionItem(form: any, values: any, rest: any) {
  const { t } = useTranslation();

  return (
    <div className="relative mt-2 flex gap-3 text-sm items-start justify-between w-full rounded-lg border border-slate-500 py-2 pl-3 pr-1 bg-slate-300">
      <div
        className="min-h-[3rem] w-12 p-0 flex justify-center items-center cursor-pointer hover:text-primary bg-white rounded-sm border-slate-200"
        {...rest.dragProps}
      >
        <DragHandle />
      </div>
      <div className="flex flex-col w-full gap-3">
        <div className="text-xs gap-2">
          <FormControl
            name={`${rest.name}.description`}
            titleKey={t("forms.description")}
            containerStyle="w-full text-white"
          />
        </div>
        <div className="text-xs gap-2">
          <FormControl
            name={`${rest.name}.price`}
            titleKey={t("forms.price")}
            containerStyle="w-full text-white"
          />
        </div>
      </div>

      <div className="">
        <Button
          type="error-line"
          onClick={() => rest.fields.remove(rest.index)}
          icon="TrashIcon"
          className="min-h-[3rem] w-12 p-0 flex justify-center"
          compact
        />
      </div>
    </div>
  );
}

function PriceItem(form: any, values: any, rest: any) {
  const { t } = useTranslation();

  return (
    <div className="relative mt-2 flex gap-3 text-sm items-start justify-between w-full rounded-lg border border-slate-200 py-2 pl-3 pr-1 bg-slate-100">
      <div
        className="min-h-[3rem] w-12 p-0 flex justify-center items-center cursor-pointer hover:text-primary bg-white rounded-sm border-slate-200"
        {...rest.dragProps}
      >
        <DragHandle />
      </div>

      <div className="flex flex-col w-full gap-3">
        <div className="text-xs gap-2">
          <FormControl
            name={`${rest.name}.title`}
            titleKey={t("forms.priceTitle")}
            containerStyle="w-full"
            required
          />
        </div>
        <div className="text-xs gap-2">
          <FormControl
            name={`${rest.name}.price`}
            titleKey={t("forms.price")}
            containerStyle="w-full"
          />
        </div>
        <div className="text-xs gap-2">
          <FormControl
            name={`${rest.name}.description`}
            titleKey={t("forms.priceDescription")}
            containerStyle="w-full"
          />
        </div>

        <div className="text-xs gap-2">
          <p className="text-sm gap-1 text-slate-500 font-bold mb-2">
            {t("forms.priceItems")}
          </p>
          <div className="flex flex-col gap-2">
            <FieldArray name={`${rest.name}.items`}>
              {({ fields: f }: any) => (
                <div>
                  <SortableList
                    items={f}
                    onMove={f.move}
                    render={(rest2) =>
                      PriceDescriptionItem(form, values, rest2)
                    }
                  />

                  <FormAddButton
                    label={t("newItem.item")}
                    onClick={() =>
                      f.push({
                        label: "",
                        validAnswer: false,
                        random: Math.floor(Math.random() * 10000),
                      })
                    }
                  />
                </div>
              )}
            </FieldArray>
          </div>
        </div>
      </div>

      <div className="">
        <Button
          type="error-line"
          onClick={() => rest.fields.remove(rest.index)}
          icon="TrashIcon"
          className="min-h-[3rem] w-12 p-0 flex justify-center"
          compact
        />
      </div>
    </div>
  );
}

function ParkingForm({ onSubmit, parking, onSynchronize }: any) {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");
  const { navigate } = useRouter();

  const parkingStatusses = [
    { value: "open", label: t("common.open") },
    { value: "closed", label: t("common.closed") },
    { value: "disabled", label: t("common.disabled") },
  ];

  const initialValues = useMemo(
    () =>
      parking?.id
        ? {
            ...parking,
            type: parking?.type?.id,
            services: parking?.services?.map((service: Service) => service.id),
          }
        : {
            name: "",
            addressGeoPoint: {
              lat: 44.84149573735524,
              lng: -0.5701025283813621,
            },
            defaultTotalParkingSpaces: 0,
            bikeParkingSpaces: 0,
            cargoBikeParkingSpaces: 0,
            twoWheelsParkingSpaces: 0,
            PRMParkingSpaces: 0,
            electricCarParkingSpaces: 0,
            services: [],
            subscriptionOnly: false,
            parkingStatus: "open",
            priceCalculatorShouldRedirectToWebSite: false,
          },
    [parking]
  );

  const { items: services } = useList<Service>("services", {
    defaultFilters: {},
    defaultSort: "name:ASC",
  });

  const onSubmitWrapper = async (values: any) => {
    const prices = values.prices?.map((price: any) => ({
      ...price,
      items: price.items || [],
    }));

    const pricesConfiguration = {
      ...values?.pricesConfiguration,
      freeItem: values.pricesConfiguration?.freeItem || [],
      packages: values.pricesConfiguration?.packages || [],
      prices: values.pricesConfiguration?.prices || [],
      night: values.pricesConfiguration?.night || [],
      bonuses: values.pricesConfiguration?.bonuses || [],
      fullDay: values.pricesConfiguration?.fullDay || [],
    };

    const tWPricesConfiguration = {
      ...values?.twoWheelsPricesConfiguration,
      freeItem: values.twoWheelsPricesConfiguration?.freeItem || [],
      packages: values.twoWheelsPricesConfiguration?.packages || [],
      prices: values.twoWheelsPricesConfiguration?.prices || [],
      night: values.twoWheelsPricesConfiguration?.night || [],
      bonuses: values.twoWheelsPricesConfiguration?.bonuses || [],
      fullDay: values.twoWheelsPricesConfiguration?.fullDay || [],
    };

    const data = {
      ...values,
      prices: prices || [],
      subscriptions: values.subscriptions || [],
      pricesConfiguration: pricesConfiguration || [],
      twoWheelsPricesConfiguration: tWPricesConfiguration || [],
    };
    const res = await onSubmit(data);

    if (res.message) {
      setErrorMessage(res.message);
    }
  };

  return (
    <Form
      onSubmit={onSubmitWrapper}
      subscription={{ values: true, submitting: true, pristine: true }}
      initialValues={initialValues}
      mutators={{
        ...arrayMutators,
        setFieldValue: ([field]: any, state: any, utils: any) => {
          utils.changeValue(state, field.field, () => field.value);
        },
        setPosition: (args: any, state: any, utils: any) => {
          const position = args[0];

          utils.changeValue(state, "addressGeoPoint.lat", () => position.lat);
          utils.changeValue(state, "addressGeoPoint.lng", () => position.lng);
        },
        setServices: (args: any, state: any, utils: any) => {
          const service = args[0];
          const currentServices = args[1];

          if (currentServices.includes(service)) {
            const index = currentServices.indexOf(service);
            currentServices.splice(index, 1);
          } else {
            currentServices.push(service);
          }
          utils.changeValue(state, "services", () => currentServices);
        },
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        setHelpPosition: ([_, geo], state, utils) => {
          utils.changeValue(
            state,
            "addressGeoPoint.lat",
            () => geo && geo.location?.lat()
          );
          utils.changeValue(
            state,
            "addressGeoPoint.lng",
            () => geo && geo.location?.lng()
          );
        },
      }}
      render={({ handleSubmit, values, form }) => {
        return (
          <div className="mx-auto max-w-full py-4 px-4 text-slate-500 text-base">
            {/* Header */}
            <EditHeader
              title={t(parking?.id ? "editItem.parking" : "newItem.parking")}
              subTitle={values.name}
              icon="BuildingOfficeIcon"
              errorMessage={errorMessage}
              HeaderButtonsEdit={
                <HeaderButtonsEdit
                  inHeader
                  onSubmit={handleSubmit}
                  onCancel={() => navigate("/parkings")}
                  onSynchronize={
                    parking ? () => onSynchronize(parking?.id) : undefined
                  }
                  synchronizeLabel={t("actions.synchronizeItem", {
                    date: parking?.lastSyncDate
                      ? dayjs(parking.lastSyncDate).format("DD MMM HH:mm")
                      : "Pas encore",
                  })}
                />
              }
            />
            <form onSubmit={handleSubmit} noValidate>
              <FormScrollError />

              <div className="flex flex-col gap-2 w-full">
                <div className="flex flex-col gap-5">
                  <div className="flex flex-row w-full gap-5">
                    <div className="flex flex-col md:w-2/3 gap-5">
                      <div className="flex flex-col white-box gap-5">
                        <SectionTitle title={t("forms.general")} />
                        <div className="flex flex-row w-full gap-5">
                          <div className="md:w-3/5">
                            <FormControl
                              name="name"
                              title={t("common.name")}
                              required
                            />
                          </div>
                          <div className="md:w-1/5">
                            <FormControl
                              name="opendataID"
                              title={t("common.opendataID")}
                            />
                          </div>
                          <div className="md:w-1/5">
                            <FormControl
                              name="modbusId"
                              title={t("common.modbusId")}
                              type="number"
                            />
                          </div>
                        </div>
                        <div className="flex flex-row w-full gap-4">
                          <FormControl
                            type="image"
                            name="image"
                            titleKey={t("forms.picture")}
                          />
                          <div className="flex w-full flex-col gap-4">
                            <FormControl
                              name="description"
                              titleKey={t("forms.description")}
                              type="richtext"
                              labelIcon="BsTextIndentLeft"
                              inputContainerStyle="flex-1"
                              containerStyle="flex w-full"
                              className="text-input"
                            />
                            <div className="flex flex-row w-full gap-5">
                              <div className="md:w-3/5">
                                <FormControl
                                  name="height"
                                  title={t("forms.height")}
                                  type="number"
                                />
                              </div>
                              <div className="md:w-2/5">
                                <FormControl
                                  type="select"
                                  title={t("forms.status")}
                                  name="parkingStatus"
                                  options={parkingStatusses}
                                  required
                                />
                              </div>
                            </div>
                            <div className="flex flex-row w-full gap-5">
                              <div className="md:w-3/5">
                                <FormControl
                                  name="linkToWebsite"
                                  title={t("forms.linkToWebsite")}
                                  required={
                                    values?.priceCalculatorShouldRedirectToWebSite
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col white-box gap-5">
                        <SectionTitle title={t("forms.service")} />
                        <div className="flex flex-row flex-wrap gap-12">
                          {services?.map((value) => {
                            return (
                              <ToggleSwitch
                                value={values.services?.includes(value.id)}
                                rightLabel={value.name}
                                onChange={() =>
                                  form.mutators.setServices(
                                    value.id,
                                    values.services
                                  )
                                }
                                key={value.id}
                              />
                            );
                          })}
                        </div>
                      </div>
                      <div className="flex flex-col white-box gap-5">
                        <SectionTitle title={t("forms.prices")} />
                        <div className="flex flex-row w-full gap-5 items-center">
                          <div className="md:w-3/5">
                            <FormControl
                              name="startingPrice"
                              title={t("forms.startingPrice")}
                              required
                            />
                          </div>
                          <div className="md:w-1/5 mt-5">
                            <FormControl type="custom" name="subscriptionOnly">
                              <ToggleSwitch
                                rightLabel={t("forms.subscriptionOnly")}
                              />
                            </FormControl>
                          </div>
                          {values?.subscriptionOnly && (
                            <div className="mt-5">
                              <FormControl type="custom" name="PMRDiscount">
                                <ToggleSwitch
                                  rightLabel={t("forms.PMRDiscount")}
                                />
                              </FormControl>
                            </div>
                          )}
                        </div>
                        <FieldArray name="prices">
                          {({ fields }: any) => (
                            <div>
                              <SortableList
                                items={fields}
                                onMove={fields.move}
                                render={(rest) => PriceItem(form, values, rest)}
                              />

                              <FormAddButton
                                label={t("newItem.item")}
                                onClick={() =>
                                  fields.push({
                                    name: "",
                                    points: 1,
                                    random: Math.floor(Math.random() * 10000),
                                  })
                                }
                              />
                            </div>
                          )}
                        </FieldArray>
                      </div>
                      <div className="flex flex-col white-box gap-5">
                        <SectionTitle title={t("forms.subscriptions")} />
                        <FieldArray name="subscriptions">
                          {({ fields }: any) => (
                            <div>
                              <SortableList
                                items={fields}
                                onMove={fields.move}
                                render={(rest) =>
                                  SubscriptionItem(form, values, rest)
                                }
                              />

                              <FormAddButton
                                label={t("newItem.item")}
                                onClick={() =>
                                  fields.push({
                                    name: "",
                                    points: 1,
                                    random: Math.floor(Math.random() * 10000),
                                  })
                                }
                              />
                            </div>
                          )}
                        </FieldArray>
                      </div>
                    </div>
                    <div className="flex flex-col md:w-1/3 gap-5">
                      <div className="flex flex-col white-box gap-5">
                        <SectionTitle title={t("forms.location")} />
                        <FormControl
                          title={t("forms.parkingType")}
                          name="type"
                          type="custom"
                          required
                        >
                          <ParkingTypeSelect />
                        </FormControl>
                        <FormControl
                          name="address"
                          title={t("common.address")}
                          required
                        />
                        <FormControl
                          name="city"
                          title={t("forms.cityPrecise")}
                        />
                        <MapField
                          position={{
                            lat: values.addressGeoPoint?.lat || 0,
                            lng: values.addressGeoPoint?.lng || 0,
                          }}
                          onChange={form.mutators.setPosition}
                          editable
                          mapPrefix="ParkingForm"
                          form={form}
                          defaultMapHeight="200px"
                          showTitle={false}
                        />
                      </div>
                      <div className="flex flex-col white-box gap-5">
                        <SectionTitle title={t("forms.places")} />
                        <div className="grid grid-cols-2 gap-4">
                          <IconFormControl
                            icon="MdOutlineDirectionsCar"
                            formControlName="defaultTotalParkingSpaces"
                            formControlTitle={t(
                              "forms.defaultTotalParkingSpaces"
                            )}
                            required
                            type="number"
                          />
                          <IconFormControl
                            icon="MdOutlineElectricCar"
                            formControlName="electricCarParkingSpaces"
                            formControlTitle={t(
                              "forms.electricCarParkingSpaces"
                            )}
                            required
                            type="number"
                          />
                          <IconFormControl
                            icon="FaMotorcycle"
                            formControlName="twoWheelsParkingSpaces"
                            formControlTitle={t("forms.twoWheelsParkingSpaces")}
                            required
                            type="number"
                          />
                          <IconFormControl
                            icon="GiDutchBike"
                            formControlName="bikeParkingSpaces"
                            formControlTitle={t("forms.bikeParkingSpaces")}
                            required
                            type="number"
                          />
                          <IconFormControl
                            icon="MdBikeScooter"
                            formControlName="cargoBikeParkingSpaces"
                            formControlTitle={t("forms.cargoBikeParkingSpaces")}
                            required
                            type="number"
                          />
                          <IconFormControl
                            icon="BiHandicap"
                            formControlName="PRMParkingSpaces"
                            formControlTitle={t("forms.PRMParkingSpaces")}
                            required
                            type="number"
                          />
                        </div>
                      </div>
                      <div className="flex flex-col white-box gap-5">
                        <SectionTitle title={t("forms.simulator")} />
                        <FormControl
                          type="custom"
                          name="priceCalculatorShouldRedirectToWebSite"
                        >
                          <ToggleSwitch
                            rightLabel={
                              values.priceCalculatorShouldRedirectToWebSite
                                ? t("forms.redirectToWebSite")
                                : t("forms.notRedirectToWebSite")
                            }
                          />
                        </FormControl>
                        {values.priceCalculatorShouldRedirectToWebSite && (
                          <FormControl
                            name="redirectToWebSiteText"
                            title={t("forms.redirectToWebSiteText")}
                            required
                          />
                        )}
                        <SectionTitle
                          title={t("forms.pricesConfigurationSumUp")}
                          small
                        />
                        {PricesConfigurationSumUp(
                          values,
                          "pricesConfiguration"
                        )}
                        <SectionTitle
                          title={t("forms.pricesConfigurationPrices")}
                          small
                        />
                        <FieldArray name="pricesConfiguration.prices">
                          {({ fields }: any) => (
                            <div>
                              <SortableList
                                items={fields}
                                onMove={fields.move}
                                render={(rest) =>
                                  PricesConfigurationItem(
                                    form,
                                    values,
                                    rest,
                                    "pricesConfiguration"
                                  )
                                }
                              />

                              <FormAddButton
                                label={t("newItem.fixedPrice")}
                                onClick={() =>
                                  fields.push({
                                    name: "",
                                    points: 1,
                                    random: Math.floor(Math.random() * 10000),
                                    price: 1,
                                    minDuration: 4,
                                  })
                                }
                              />
                            </div>
                          )}
                        </FieldArray>
                        <SectionTitle
                          title={t("forms.pricesConfigurationPackages")}
                          small
                        />
                        <FieldArray name="pricesConfiguration.packages">
                          {({ fields }: any) => (
                            <div>
                              <SortableList
                                items={fields}
                                onMove={fields.move}
                                render={(rest) =>
                                  PricesConfigurationPackage(form, values, rest)
                                }
                              />

                              <FormAddButton
                                label={t("newItem.package")}
                                onClick={() =>
                                  fields.push({
                                    name: "",
                                    points: 1,
                                    random: Math.floor(Math.random() * 10000),
                                    minDuration: 32,
                                    maxDuration: 96,
                                    price: 1,
                                  })
                                }
                              />
                            </div>
                          )}
                        </FieldArray>
                        <SectionTitle
                          title={t("forms.pricesConfigurationNight")}
                          small
                        />
                        <FieldArray name="pricesConfiguration.night">
                          {({ fields }: any) => (
                            <div>
                              <SortableList
                                items={fields}
                                onMove={fields.move}
                                render={(rest) =>
                                  PricesConfigurationNight(form, values, rest)
                                }
                              />

                              {fields?.length < 1 && (
                                <FormAddButton
                                  label={t("newItem.night")}
                                  onClick={() =>
                                    fields.push({
                                      name: "",
                                      points: 1,
                                      random: Math.floor(Math.random() * 10000),
                                      startingTime: dayjs().hour(20).minute(0),
                                      endingTime: dayjs().hour(7).minute(0),
                                      minDuration: 6,
                                      price: 1,
                                    })
                                  }
                                />
                              )}
                            </div>
                          )}
                        </FieldArray>
                        <SectionTitle
                          title={t("forms.pricesConfigurationFullDay")}
                          small
                        />
                        <FieldArray name="pricesConfiguration.fullDay">
                          {({ fields }: any) => (
                            <div>
                              <SortableList
                                items={fields}
                                onMove={fields.move}
                                render={(rest) =>
                                  PricesConfigurationFullDay(form, values, rest)
                                }
                              />
                              {fields?.length < 1 && (
                                <FormAddButton
                                  label={t("newItem.fullDay")}
                                  onClick={() =>
                                    fields.push({
                                      name: "",
                                      points: 1,
                                      random: Math.floor(Math.random() * 10000),
                                      price: 1,
                                    })
                                  }
                                />
                              )}
                            </div>
                          )}
                        </FieldArray>
                        <SectionTitle
                          title={t("forms.pricesConfigurationFree")}
                          small
                        />
                        <FieldArray name="pricesConfiguration.freeItem">
                          {({ fields }: any) => (
                            <div>
                              <SortableList
                                items={fields}
                                onMove={fields.move}
                                render={(rest) =>
                                  PricesConfigurationFree(form, values, rest)
                                }
                              />

                              {fields?.length < 1 && (
                                <FormAddButton
                                  label={t("newItem.free")}
                                  onClick={() =>
                                    fields.push({
                                      name: "",
                                      points: 1,
                                      random: Math.floor(Math.random() * 10000),
                                      duration: 4,
                                    })
                                  }
                                />
                              )}
                            </div>
                          )}
                        </FieldArray>
                        <SectionTitle
                          title={t("forms.pricesConfigurationBonuses")}
                          small
                        />
                        <FieldArray name="pricesConfiguration.bonuses">
                          {({ fields }: any) => (
                            <div>
                              <SortableList
                                items={fields}
                                onMove={fields.move}
                                render={(rest) =>
                                  PricesConfigurationPackageBonus(
                                    form,
                                    values,
                                    rest
                                  )
                                }
                              />

                              <FormAddButton
                                label={t("newItem.bonuses")}
                                onClick={() =>
                                  fields.push({
                                    name: "",
                                    points: 1,
                                    random: Math.floor(Math.random() * 10000),
                                    endingTime: dayjs().add(1, "hour"),
                                    price: 1,
                                  })
                                }
                              />
                            </div>
                          )}
                        </FieldArray>
                      </div>
                      <div className="flex flex-col white-box gap-5">
                        <SectionTitle title={t("forms.simulatorTwoWheels")} />
                        <SectionTitle
                          title={t("forms.pricesConfigurationSumUp")}
                          small
                        />
                        {PricesConfigurationSumUp(
                          values,
                          "twoWheelsPricesConfiguration"
                        )}
                        <SectionTitle
                          title={t("forms.pricesConfigurationPrices")}
                          small
                        />
                        <FieldArray name="twoWheelsPricesConfiguration.prices">
                          {({ fields }: any) => (
                            <div>
                              <SortableList
                                items={fields}
                                onMove={fields.move}
                                render={(rest) =>
                                  PricesConfigurationItem(
                                    form,
                                    values,
                                    rest,
                                    "twoWheelsPricesConfiguration"
                                  )
                                }
                              />

                              <FormAddButton
                                label={t("newItem.fixedPrice")}
                                onClick={() =>
                                  fields.push({
                                    name: "",
                                    points: 1,
                                    random: Math.floor(Math.random() * 10000),
                                    price: 1,
                                    minDuration: 4,
                                  })
                                }
                              />
                            </div>
                          )}
                        </FieldArray>
                        <SectionTitle
                          title={t("forms.pricesConfigurationPackages")}
                          small
                        />
                        <FieldArray name="twoWheelsPricesConfiguration.packages">
                          {({ fields }: any) => (
                            <div>
                              <SortableList
                                items={fields}
                                onMove={fields.move}
                                render={(rest) =>
                                  PricesConfigurationPackage(form, values, rest)
                                }
                              />

                              <FormAddButton
                                label={t("newItem.package")}
                                onClick={() =>
                                  fields.push({
                                    name: "",
                                    points: 1,
                                    random: Math.floor(Math.random() * 10000),
                                    minDuration: 32,
                                    maxDuration: 96,
                                    price: 1,
                                  })
                                }
                              />
                            </div>
                          )}
                        </FieldArray>
                        <SectionTitle
                          title={t("forms.pricesConfigurationNight")}
                          small
                        />
                        <FieldArray name="twoWheelsPricesConfiguration.night">
                          {({ fields }: any) => (
                            <div>
                              <SortableList
                                items={fields}
                                onMove={fields.move}
                                render={(rest) =>
                                  PricesConfigurationNight(form, values, rest)
                                }
                              />

                              {fields?.length < 1 && (
                                <FormAddButton
                                  label={t("newItem.night")}
                                  onClick={() =>
                                    fields.push({
                                      name: "",
                                      points: 1,
                                      random: Math.floor(Math.random() * 10000),
                                      startingTime: dayjs().hour(20).minute(0),
                                      endingTime: dayjs().hour(7).minute(0),
                                      minDuration: 6,
                                      price: 1,
                                    })
                                  }
                                />
                              )}
                            </div>
                          )}
                        </FieldArray>
                        <SectionTitle
                          title={t("forms.pricesConfigurationFullDay")}
                          small
                        />
                        <FieldArray name="twoWheelsPricesConfiguration.fullDay">
                          {({ fields }: any) => (
                            <div>
                              <SortableList
                                items={fields}
                                onMove={fields.move}
                                render={(rest) =>
                                  PricesConfigurationFullDay(form, values, rest)
                                }
                              />
                              {fields?.length < 1 && (
                                <FormAddButton
                                  label={t("newItem.fullDay")}
                                  onClick={() =>
                                    fields.push({
                                      name: "",
                                      points: 1,
                                      random: Math.floor(Math.random() * 10000),
                                      price: 1,
                                    })
                                  }
                                />
                              )}
                            </div>
                          )}
                        </FieldArray>
                        <SectionTitle
                          title={t("forms.pricesConfigurationFree")}
                          small
                        />
                        <FieldArray name="twoWheelsPricesConfiguration.freeItem">
                          {({ fields }: any) => (
                            <div>
                              <SortableList
                                items={fields}
                                onMove={fields.move}
                                render={(rest) =>
                                  PricesConfigurationFree(form, values, rest)
                                }
                              />

                              {fields?.length < 1 && (
                                <FormAddButton
                                  label={t("newItem.free")}
                                  onClick={() =>
                                    fields.push({
                                      name: "",
                                      points: 1,
                                      random: Math.floor(Math.random() * 10000),
                                      duration: 4,
                                    })
                                  }
                                />
                              )}
                            </div>
                          )}
                        </FieldArray>
                        <SectionTitle
                          title={t("forms.pricesConfigurationBonuses")}
                          small
                        />
                        <FieldArray name="twoWheelsPricesConfiguration.bonuses">
                          {({ fields }: any) => (
                            <div>
                              <SortableList
                                items={fields}
                                onMove={fields.move}
                                render={(rest) =>
                                  PricesConfigurationPackageBonus(
                                    form,
                                    values,
                                    rest
                                  )
                                }
                              />

                              <FormAddButton
                                label={t("newItem.bonuses")}
                                onClick={() =>
                                  fields.push({
                                    name: "",
                                    points: 1,
                                    random: Math.floor(Math.random() * 10000),
                                    endingTime: dayjs().add(1, "hour"),
                                    price: 1,
                                  })
                                }
                              />
                            </div>
                          )}
                        </FieldArray>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <HeaderButtonsEdit
                onSubmit={handleSubmit}
                onCancel={() => navigate("/parkings")}
              />
            </form>
          </div>
        );
      }}
    />
  );
}

export default ParkingForm;
