function LoginHeader() {
  return (
    <div className="sm:mx-auto sm:w-full sm:max-w-md">
      <img
        className="mx-auto h-[220px] w-auto"
        src="images/main-logo.png"
        alt="Toulouse-eco Logo"
      />
    </div>
  );
}

export default LoginHeader;
