import { IRadioBlockItemData } from "common/components/RadioBlock/RadioBlock";

const rolesData: IRadioBlockItemData[] = [
  {
    id: 1,
    icon: "BsStarHalf",
    value: "Authenticated",
    titleKey: "roles.Pro",
    subTitleKey: "roles.ProSubtitle",
    containerSelStyle:
      "bg-ternary-400 border-ternary-400 shadow-lg ternary-200",
    iconStyle: "text-white",
    iconContainerStyle: "bg-ternary-400",
    iconSelStyle: "text-ternary-400",
    iconContainerSelStyle: "bg-white",
  },
  {
    id: 3,
    icon: "BsStarFill",
    value: "Admin",
    titleKey: "roles.Administrateur",
    subTitleKey: "roles.AdministrateurSubtitle",
    containerSelStyle:
      "bg-ternary-400 border-ternary-400 shadow-lg ternary-200",
    iconStyle: "text-white",
    iconContainerStyle: "bg-ternary-400",
    iconSelStyle: "text-ternary-400",
    iconContainerSelStyle: "bg-white",
  },
];

export default rolesData;
