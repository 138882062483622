import EditHeader from "common/components/EditHeader/EditHeader";
import HeaderButtonsEdit from "common/components/EditHeader/HeaderButtonsEdit";
import FormControl from "common/components/FormControl/FormControl";
import FormScrollError from "common/components/FormScrollError/FormScrollError";
import useRouter from "common/hooks/use-router";
import { useMemo, useState } from "react";
import { Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import arrayMutators from "final-form-arrays";

function ServiceForm({ onSubmit, service }: any) {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");
  const { navigate } = useRouter();

  const initialValues = useMemo(
    () =>
      service?.id
        ? {
            ...service,
          }
        : {
            name: "",
          },
    [service]
  );

  const onSubmitWrapper = async (values: any) => {
    const res = await onSubmit(values);

    if (res.message) {
      setErrorMessage(res.message);
    }
  };

  return (
    <Form
      onSubmit={onSubmitWrapper}
      subscription={{ values: true, submitting: true, pristine: true }}
      initialValues={initialValues}
      mutators={{
        ...arrayMutators,
        setFieldValue: ([field]: any, state: any, utils: any) => {
          utils.changeValue(state, field.field, () => field.value);
        },
      }}
      render={({ handleSubmit, values }) => {
        return (
          <div className="mx-auto max-w-full py-4 px-4 text-slate-500 text-base">
            {/* Header */}
            <EditHeader
              title={t(service?.id ? "editItem.service" : "newItem.service")}
              subTitle={values.name}
              icon="MdOutlineWidgets"
              errorMessage={errorMessage}
              HeaderButtonsEdit={
                <HeaderButtonsEdit
                  inHeader
                  onSubmit={handleSubmit}
                  onCancel={() => navigate("/services")}
                />
              }
            />
            <form onSubmit={handleSubmit} noValidate>
              <FormScrollError />

              <div className="responsive-flex-lg gap-2 mt-2">
                <div className="lg:flex-1">
                  <div className="flex flex-col gap-2 w-full">
                    <div className="white-box flex flex-row gap-2">
                      <FormControl
                        name="name"
                        title={t("common.name")}
                        required
                      />
                      <FormControl
                        name="mtpkId"
                        titleKey={t("common.remoteId")}
                        required
                        type="number"
                      />
                      <FormControl
                        name="iconName"
                        title={t("common.iconName")}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <HeaderButtonsEdit
                onSubmit={handleSubmit}
                onCancel={() => navigate("/services")}
              />
            </form>
          </div>
        );
      }}
    />
  );
}

export default ServiceForm;
