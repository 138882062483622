import Select from "common/components/Select/Select";

const generateDaySchedule = () => {
  const schedule: { value: number; label: string }[] = [];

  for (let i = 0; i <= 24 * 4; i++) {
    const hour = Math.floor(i / 4);
    const minute = (i % 4) * 15;

    let label = "";
    if (hour !== 0) {
      label += `${hour}h`;
    }
    if ((minute !== 0 && hour === 0) || hour === 0) {
      label += `${minute}min`;
    } else if (minute !== 0) {
      label += `${minute}`;
    }

    schedule.push({
      value: i,
      label,
    });
  }

  return schedule;
};

function TimeSelect(props: any) {
  return <Select options={generateDaySchedule()} {...props} />;
}

export default TimeSelect;
