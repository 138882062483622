import { useTranslation } from "react-i18next";
import Select from "common/components/Select/Select";

function RoleSelect(props: any) {
  const { t } = useTranslation();

  const { userType } = props;

  const options = [
    // { value: "Authenticated", label: t("roles.Authenticated") },
    { value: "Authenticated", label: t("roles.Pro") },
    { value: "Admin", label: t("roles.Administrateur") },
  ];

  return (
    <Select
      options={options}
      icon="StarIcon"
      disabled={!userType?.isAdmin}
      {...props}
    />
  );
}

export default RoleSelect;
