// import Loader from 'common/components/Loader/Loader';
import AlertsContext from "common/providers/alerts";
import useRouter from "common/hooks/use-router";
import useItem from "common/hooks/useItem";
import { useCallback, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ParkingForm from "./ParkingForm";

function Parking() {
  const { t } = useTranslation();
  const router = useRouter();
  const { setAlert } = useContext(AlertsContext);

  const id = router.query.id as string;
  const isDuplicated = router.pathname.includes("duplicate");

  const handleSynchronizeParking = () => {
    console.log(`synchronise : ${id}`);
  };

  const { saveItem, updateItem, item, error, isFetching }: any = useItem(
    "parkings",
    id,
    {
      populate: [
        "type",
        "services",
        "image",
        "prices",
        "prices.items",
        "subscriptions",
        "pricesConfiguration",
        "pricesConfiguration.freeItem",
        "pricesConfiguration.packages",
        "pricesConfiguration.prices",
        "pricesConfiguration.night",
        "pricesConfiguration.bonuses",
        "pricesConfiguration.fullDay",
        "twoWheelsPricesConfiguration",
        "twoWheelsPricesConfiguration.freeItem",
        "twoWheelsPricesConfiguration.packages",
        "twoWheelsPricesConfiguration.prices",
        "twoWheelsPricesConfiguration.night",
        "twoWheelsPricesConfiguration.bonuses",
        "twoWheelsPricesConfiguration.fullDay",
      ],
    }
  );

  useEffect(() => {
    if (error) {
      setAlert(error, "danger");
    }
  }, [error, setAlert]);

  const back = useCallback(() => {
    if (!error) {
      setAlert(t("common.saved"), "success");
      router.navigate("/parkings");
    }
  }, [setAlert, router, error, t]);

  const onSubmit = useCallback(
    async (dataParking: any) => {
      const newParking = { ...dataParking };

      if (isDuplicated) {
        delete newParking.id;
      }

      try {
        if (newParking.id) {
          await updateItem(newParking.id, newParking);
        } else {
          await saveItem(newParking);
        }
      } catch (e: any) {
        return e;
      }
      back();
      return true;
    },
    [isDuplicated, back, updateItem, saveItem]
  );

  return isFetching || (id && !item) ? null : (
    <ParkingForm
      onSubmit={onSubmit}
      parking={item}
      onSynchronize={handleSynchronizeParking}
    />
  );
}

export default Parking;
