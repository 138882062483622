import {
  SparklesIcon,
  ClipboardDocumentListIcon,
  InformationCircleIcon,
} from "@heroicons/react/20/solid";
import { ClockIcon, UserGroupIcon } from "@heroicons/react/24/outline";
import WorkSiteIcon from "components/Icon/icons/WorkSiteIcon";
import { useTranslation } from "react-i18next";
import { BiHourglass, BiMobile } from "react-icons/bi";
import { BsStarFill, BsStarHalf } from "react-icons/bs";

interface IStatus {
  status?: string | null;
  label?: string;
  color?: string | null;
  icon?: any;
  small?: boolean;
}

function Status({ status, label, color, icon, small = false }: IStatus) {
  const { t } = useTranslation();
  if (!status) {
    return null;
  }

  let style = "bg-slate-500";
  let text = label;
  let Icon = icon;

  // success
  if (status === "published") {
    style = "bg-green-500";
    text = t("status.online");
    Icon = SparklesIcon;
  }

  // promise
  if (status === "draft") {
    style = "bg-blue-500";
    text = t("status.draft");
    Icon = ClockIcon;
  }

  if (status === "disabled") {
    style = "bg-slate-500";
    text = t("status.offline");
    Icon = BiHourglass;
  }

  if (status === "confirmed") {
    style = "bg-green-500";
    text = t("forms.confirmed");
    Icon = SparklesIcon;
  }

  if (status === "unconfirmed") {
    style = "bg-yellow-500";
    text = t("forms.unconfirmed");
    Icon = ClipboardDocumentListIcon;
  }

  if (status === "Authenticated") {
    style = "bg-ternary-300";
    text = t("roles.Pro");
    Icon = BsStarHalf;
  }

  if (status === "Admin") {
    style = "bg-ternary-400";
    text = t("roles.Administrateur");
    Icon = BsStarFill;
  }

  // audiance

  if (status === "both") {
    style = "bg-blue-900";
    text = t("audiance.both", {
      appName: t("forms.privateUntitled"),
    });
    Icon = BiMobile;
  }

  if (status === "public") {
    style = "bg-blue-900";
    text = t("audiance.public");
    Icon = BiMobile;
  }

  if (status === "private") {
    style = "bg-blue-900";
    text = t("audiance.private", {
      appName: t("forms.privateUntitled"),
    });
    Icon = BiMobile;
  }

  // site type

  if (
    status === "usine" ||
    status === "worksite" ||
    status === "site" ||
    status === "theme" ||
    status === "project"
  ) {
    style = `bg-${color}-500`;
    text = t(`sites.type_${status}`);
    // Icon = icon;
  }

  if (status === "usine" || status === "worksite") {
    Icon = WorkSiteIcon;
  }

  if (status === "site" || status === "theme") {
    Icon = InformationCircleIcon;
  }

  if (status === "project") {
    Icon = UserGroupIcon;
  }

  const baseStyle = small
    ? "py-1 px-2 h-6 text-[0.8rem]"
    : "py-2 px-4 h-8 text-[1rem]";

  return (
    <div
      className={`flex items-center gap-1 font-normal leading-[0] text-center text-white rounded-full ${baseStyle} ${style} shrink-0`}
    >
      {Icon && <Icon className="w-3 h-3" />} {text}
    </div>
  );
}

export default Status;
