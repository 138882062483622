import Button from "common/components/Button/Button";

import { Link } from "react-router-dom";
import useAuth from "common/hooks/useAuth";
import { useTranslation } from "react-i18next";
import truncateString from "common/utils/truncateString";
import getFlattenText from "common/utils/getFlattenText";
import { Parking } from "types/Parking";
import InfoGroup, { Info } from "common/components/InfoGroup/InfoGroup";
import ImageComponent from "common/components/ImageComponent/ImageComponent";
import ImageFromStrapiMedia from "common/components/ImageFromStrapiMedia/ImageFromStrapiMedia";
import dayjs from "dayjs";

// TODO put onDuplicate back if needed
function ParkingItem({ item, onDelete, onNavigate, onSynchronize }: any) {
  const { user: currentUser } = useAuth();
  const canEditItem = currentUser?.isAdmin || currentUser?.isAuthenticated;

  const {
    name,
    description,
    type,
    image,
    defaultTotalParkingSpaces,
    bikeParkingSpaces,
    cargoBikeParkingSpaces,
    twoWheelsParkingSpaces,
    electricCarParkingSpaces,
    PRMParkingSpaces,
    lastSyncDate,
  } = item as Parking;

  const editLink = `/parkings/edit/${item.id}`;

  const { t } = useTranslation();

  const info: Info[] = [
    {
      name: type?.name ?? "",
      infoKey: "forms.parkingType",
      icon: "BuildingOffice2Icon",
    },
  ];

  const places: Info[] = [
    {
      name: defaultTotalParkingSpaces?.toString(),
      infoKey: "forms.defaultTotalParkingSpaces",
      icon: "MdOutlineDirectionsCar",
      visible:
        defaultTotalParkingSpaces !== undefined &&
        defaultTotalParkingSpaces > 0,
    },
    {
      name: electricCarParkingSpaces?.toString(),
      infoKey: "forms.electricCarParkingSpaces",
      icon: "MdOutlineElectricCar",
      visible:
        electricCarParkingSpaces !== undefined && electricCarParkingSpaces > 0,
    },
    {
      name: twoWheelsParkingSpaces?.toString(),
      infoKey: "forms.twoWheelsParkingSpaces",
      icon: "FaMotorcycle",
      visible:
        twoWheelsParkingSpaces !== undefined && twoWheelsParkingSpaces > 0,
    },
    {
      name: bikeParkingSpaces?.toString(),
      infoKey: "forms.bikeParkingSpaces",
      icon: "GiDutchBike",
      visible: bikeParkingSpaces !== undefined && bikeParkingSpaces > 0,
    },
    {
      name: cargoBikeParkingSpaces?.toString(),
      infoKey: "forms.cargoBikeParkingSpaces",
      icon: "MdBikeScooter",
      visible:
        cargoBikeParkingSpaces !== undefined && cargoBikeParkingSpaces > 0,
    },
    {
      name: PRMParkingSpaces?.toString(),
      infoKey: "forms.PRMParkingSpaces",
      icon: "BiHandicap",
      visible: PRMParkingSpaces !== undefined && PRMParkingSpaces > 0,
    },
  ];

  return (
    <div className="responsive-flex-xl px-4 py-4 mt-2 w-full relative bg-white cursor-pointer rounded-lg border border-slate-150 transition-all">
      <Link
        to={editLink}
        className={
          !canEditItem ? "pointer-events-none flex flex-1" : "flex flex-1"
        }
      >
        <div className="responsive-flex-xl mb-4 xl:mb-0 w-full gap-2 xl:gap-4 pr:0 xl:pr-4">
          <div className="flex relative w-full h-40  xl:w-24 xl:h-24 shrink-0">
            <div className="rounded-lg overflow-hidden w-full">
              <ImageComponent
                image={image ? ImageFromStrapiMedia(image)?.uri : undefined}
              />
            </div>
          </div>

          <div className="flex flex-col flex-1 justify-start overflow-hidden">
            {/* head */}
            <div className="flex flex-col xl:flex-row w-full gap-4">
              <div className="flex flex-col flex-1">
                <h4 className="text-xl font-bold text-primary leading-6">
                  {name}
                </h4>
              </div>
            </div>

            {/* foot */}
            <div className="flex flex-col gap-2 pt-1 mt-2 border-t border-slate-200">
              <div className="responsive-flex-lg mt-1">
                <InfoGroup infoGroups={places} />
              </div>
              <div className="text-sm text-gray-500 ">
                {truncateString(getFlattenText(description), 200)}
              </div>
            </div>
          </div>
        </div>
      </Link>

      <div className="flex flex-col justify-between items-end text-xs xl:border-l xl:pl-4 xl:min-w-[22em] border-slate-150 gap-2">
        <div className="responsive-flex-lg mt-1">
          <InfoGroup infoGroups={info} />
        </div>
        <div className="flex w-full items-center justify-end text-slate-500">
          <div>
            <div className="flex w-full gap-1">
              {/* <Button
                type="error-line"
                icon="DocumentDuplicateIcon"
                compact
                onClick={() => onDuplicate(item.id)}
                disabled={!canEditItem}
              /> */}
              <Button
                title={t("actions.synchronizeItem", {
                  date: lastSyncDate
                    ? dayjs(lastSyncDate).format("DD MMM HH:mm")
                    : "Pas encore",
                })}
                type="purple-line"
                icon="IoRefreshCircle"
                onClick={() => onSynchronize(item.id)}
                disabled={!canEditItem}
                className="normal-case"
              />
              <Button
                title={t("actions.edit")}
                type="valid-line"
                icon="PencilSquareIcon"
                onClick={() => onNavigate(item.id)}
                disabled={!canEditItem}
              />
              <Button
                type="error-line"
                icon="TrashIcon"
                compact
                onClick={() => onDelete(item.id)}
                disabled={!canEditItem}
                confirmMessage={t("actions.confirmDelete")}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ParkingItem;
