// import Loader from 'common/components/Loader/Loader';
import AlertsContext from "common/providers/alerts";
import useRouter from "common/hooks/use-router";
import useItem from "common/hooks/useItem";
import { useCallback, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ParkingType as ParkingTypeType } from "types/ParkingType";
import ParkingTypeForm from "./ParkingTypeForm";

function ParkingType() {
  const { t } = useTranslation();
  const router = useRouter();
  const { setAlert } = useContext(AlertsContext);

  const id = router.query.id as string;

  const { saveItem, updateItem, item, error, isFetching }: any =
    useItem<ParkingTypeType>("parking-types", id);

  useEffect(() => {
    if (error) {
      setAlert(error, "danger");
    }
  }, [error, setAlert]);

  const back = useCallback(() => {
    if (!error) {
      setAlert(t("common.saved"), "success");
      router.navigate("/parking-types");
    }
  }, [setAlert, router, error, t]);

  const onSubmit = useCallback(
    async (dataParkingType: any) => {
      const newParkingType = { ...dataParkingType };

      try {
        if (newParkingType.id) {
          await updateItem(newParkingType.id, newParkingType);
        } else {
          await saveItem(newParkingType);
        }
      } catch (e: any) {
        return e;
      }
      back();
      return true;
    },
    [updateItem, back, saveItem]
  );

  return isFetching || (id && !item) ? null : (
    <ParkingTypeForm onSubmit={onSubmit} parkingType={item} />
  );
}

export default ParkingType;
