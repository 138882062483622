import RemoteSelect from "common/components/RemoteSelect/RemoteSelect";
import { Parking } from "types/Parking";

const processOptions = (options: Parking[]) =>
  options.map(({ id, name, addressGeoPoint }) => ({
    value: {
      id,
      lat: (addressGeoPoint as any)?.lat,
      lng: (addressGeoPoint as any)?.lng,
    },
    label: name,
  }));

function ParkingSelect(props: any) {
  return (
    <RemoteSelect
      url="parkings"
      processOptions={processOptions}
      sort="name:ASC"
      comparedField="id"
      {...props}
    />
  );
}

export default ParkingSelect;
